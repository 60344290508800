import { template as template_f55c82dfee3648e6b76090def998daac } from "@ember/template-compiler";
const FKLabel = template_f55c82dfee3648e6b76090def998daac(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
