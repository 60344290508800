import { template as template_ffcc64c087174089848e304b5fcf03fc } from "@ember/template-compiler";
import { and } from "truth-helpers";
import ItemRepliesCell from "discourse/components/topic-list/item/replies-cell";
import TopicPostBadges from "discourse/components/topic-post-badges";
const PostCountOrBadges = template_ffcc64c087174089848e304b5fcf03fc(`
  {{#if (and @postBadgesEnabled @topic.unread_posts)}}
    <TopicPostBadges
      @unreadPosts={{@topic.unread_posts}}
      @unseen={{@topic.unseen}}
      @url={{@topic.lastUnreadUrl}}
    />
  {{else}}
    <ItemRepliesCell @topic={{@topic}} @tagName="div" />
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostCountOrBadges;
