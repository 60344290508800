import { template as template_d546a36e6d514a40b684891a0aa3159c } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_d546a36e6d514a40b684891a0aa3159c(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
